// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anfahrt-js": () => import("./../../../src/pages/anfahrt.js" /* webpackChunkName: "component---src-pages-anfahrt-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-garten-js": () => import("./../../../src/pages/garten.js" /* webpackChunkName: "component---src-pages-garten-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preise-und-buchung-js": () => import("./../../../src/pages/preise_und_buchung.js" /* webpackChunkName: "component---src-pages-preise-und-buchung-js" */),
  "component---src-pages-umgebung-js": () => import("./../../../src/pages/umgebung.js" /* webpackChunkName: "component---src-pages-umgebung-js" */),
  "component---src-pages-wohnungen-js": () => import("./../../../src/pages/wohnungen.js" /* webpackChunkName: "component---src-pages-wohnungen-js" */)
}

